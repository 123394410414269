import React from "react";
import { Container, Row } from "react-bootstrap";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Title from "../components/Title";
import Seo from "../components/seo";
import styled from "styled-components";
import { renderRichText } from "gatsby-source-contentful/rich-text";

const AboutMe = ({ location, path }) => {
  const { contentfulAboutMePage, allContentfulAboutMePage } =
    useStaticQuery(data);
  const { gatsbyImageData } = contentfulAboutMePage.image;
  const { richContent, skills } = allContentfulAboutMePage.edges[0].node;

  return (
    <Layout location={location} path={path}>
      <Seo title="About me" />
      <Container>
        <Row className="pt-5">
          <Title title="About me" position="center" pageTitle />
        </Row>
        <Row className="pb-5">
          <div className="d-flex flex-column align-items-center">
            <div>
              <StyledGatsbyImage alt="Headshot" image={gatsbyImageData} />
            </div>
            <div className="mt-5 p-sm-5">{renderRichText(richContent)}</div>
            <div className="mb-0">
              <a href="cv.pdf" download="cv.pdf">
                <StyledButton>Download CV</StyledButton>
              </a>
            </div>
          </div>
        </Row>
        <Row>
          <StyledSkills className="skills mt-0 p-sm-5">
            {renderRichText(skills)}
          </StyledSkills>
        </Row>
      </Container>
    </Layout>
  );
};

const StyledButton = styled.button`
  padding: 20px 80px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  background-size: 300% 100%;
  border: none;

  border-radius: 20px;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;

  &:hover {
    color: white;
    background-position: 100% 0;
    moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }

  &:focus {
    outline: none;
  }

  background-image: linear-gradient(
    to right,
    #f5ce62,
    #e43603,
    #fa7199,
    #e85a19
  );
  box-shadow: 0 4px 15px 0 rgba(229, 66, 10, 0.75);
`;

const StyledGatsbyImage = styled(GatsbyImage)`
  -webkit-border-radius: 50%;
  border-radius: 50%;
`;

const StyledSkills = styled.div`
  td {
    padding-right: 100px;
  }
`;

export const data = graphql`
  query AboutMeQuery {
    allContentfulAboutMePage {
      edges {
        node {
          richContent {
            raw
          }
          skills {
            raw
          }
        }
      }
    }
    contentfulAboutMePage {
      image {
        gatsbyImageData(width: 250)
      }
    }
  }
`;

export default AboutMe;
